import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import "./header.scss"
import logo from "../images/prs-logo-black.png"

const Header = ({ siteTitle }) => {
  return (
    <header
      className={`fixed left-0 top-0 right-0  z-50 pt-5 pl-3 pr-3 shadow-md background bg-white `}
    >
      <div className="flex max-w-4xl mx-auto justify-around md:justify-between">
        <div>
          <h1 className="text-xl text-center md:text-left">
            <a href="https://www.prsguitars.com">
              <img
                src={logo}
                alt="PRS Guitars"
                className="w-7/12 sm:w-10/12 md:w-7/12 z-50"
              />
            </a>
          </h1>
        </div>
        <nav className="flex-none">
          <ul className="flex glex flex-col sm:flex-row uppercase text-sm sm:pt-6 leading-tight font-header text-right">
            <li>
              <Link to="/#story">Celebrating 35 Years</Link>
            </li>
            <li className="pl-3">
              <Link to="/#guitars">Anniversary Guitars</Link>
            </li>
            <li className="pl-3">
              <Link to="/timeline">35 Years: A Timeline</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
